import React from "react"
import { graphql, Link } from "gatsby"
import { FaHome } from "react-icons/fa"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />

      <h3>Uhmm...Looks like the page is on vacation and never came back.</h3>
      <iframe
        src="https://giphy.com/embed/CM2G0NiOzjWjC"
        width="480"
        height="272"
        frameBorder="0"
        class="giphy-embed"
        allowFullScreen
      ></iframe>
      <p style={{ fontSize: 12 }}>
        <a href="https://giphy.com/gifs/minions-vacation-maid-CM2G0NiOzjWjC">
          via GIPHY
        </a>
      </p>
      <h3>
        {" "}
        Want to try starting from{" "}
        <Link to="/">
          <FaHome />?
        </Link>
      </h3>

      <hr />
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
